import React, { useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from '../utils/MetaTags';
import '../styles/ComputerConnection.scss';

const ComputerConnection = () => {
    const { t } = useTranslation();

    const keywords = {
        en: "otary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
        ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
        ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик",
      };
    
      const description = {
        en: "otary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
        ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
        ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик",
      };

    useEffect(() => {
        window.scrollTo(0, 0);
    },);

    return (
        <section className='connection'>
            <MetaTags keywords={keywords} description={description} />
            <div className="connection-content wrapper">
                <h3>{t("connection")}</h3>
                <div className="connection-content__main">
                    <div className="connection-content__txt">
                        <p>{t("connectionTxt")}</p>
                    </div>
                    <div className="connection-content__img">
                        <img src={process.env.PUBLIC_URL + "./pcConnecting/1.webp"} alt="image" />
                        <p>{t("connectionImg")}</p>
                    </div>
                </div>
                <div className="connection-content__desc">
                    <p>{t("connectionDesc.1")}</p>
                    <p>{t("connectionDesc.2")}</p>
                    <p>{t("connectionDesc.3")}</p>
                </div>
                <div className="connection-content__list">
                    <h4>{t("connectionList.1")}</h4>
                    <ul>
                        <li>{t("connectionList.2")}</li>
                        <li>{t("connectionList.3")}</li>
                        <li>{t("connectionList.4")}</li>
                        <li>{t("connectionList.5")}</li>
                    </ul>
                </div>
                <div className="connection-content__connected">
                    <div className="connection-content__pic">
                        <img src={process.env.PUBLIC_URL + "./pcConnecting/2.webp"} alt="image" />
                        <p>{t("connectionPic")}</p>
                    </div>
                    <div className="connection-content__info">
                        <p>{t("connectionInfo")}</p>
                    </div>
                </div>
                <div className="connection-content__text">
                    <p>{t("connectionText.1")}</p>
                    <p>{t("connectionText.2")}</p>
                </div>
            </div>
        </section>
    );
};

export default ComputerConnection;